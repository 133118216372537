import React from "react";
import { logo } from "../assets/image";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import logo1 from "../assets/logo (1).png";
const Logo = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <Link to={""}>
      <div className="flex items-center gap-2 logo">
        <div className=" h-[35px] md:h-[45px]">
          <img
            src={logo}
            alt="newsky"
            className="w-full h-full object-contain"
          />
        </div>
        <div>
          <h1 className=" text-xl font-semibold text-[#21a8f4]">
            Fly<span className="text-[#192abf] ml-[2px]">Moon</span>{" "}
          </h1>
        </div>
      </div>
    </Link>
  );
};

export default Logo;
